import { TextField } from "formik-mui";
import { compose, withHooks } from "enhancers";

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useState, useCallback } = hooks;
    const [showPassword, setShowPassword] = useState(false);
    const { field } = props;

    const handleClickShowPassword = useCallback(() => {
      setShowPassword(!showPassword);
    }, [showPassword]);

    const type = useMemo(() => (showPassword ? "text" : props.type), [showPassword, props.type]);

    const focused = useMemo(() => {
      return field.value ? true : false;
    }, [field.value]);

    const inputProps = useMemo(() => {
      let customInputProps = {};
      if (props.textAlign) {
        customInputProps = { style: { textAlign: props.textAlign } };
      }

      customInputProps = {
        ...customInputProps,
        thousandSeparator: props.thousandSeparator,
      };

      customInputProps = {
        ...customInputProps,
        ...props.inputProps,
      };

      return customInputProps;
    }, [props.textAlign, props.inputProps, props.thousandSeparator]);

    return {
      inputProps,
      ...props,
      type,
      InputLabelProps: { shrink: true },
      focused: focused,
    };
  })
);

const _TextField = enhancer(TextField);

export default _TextField;
