// @ts-nocheck
import MKTypography from "components/MKTypography";
import NoDataTypography from "components/NoDataTypography";
import { Container, Grid } from "../../components/main/index";
import SimpleCards from "components/SimpleCards";
import { isEmpty } from "lodash";
import MKBox from "components/MKBox";

const NewsAndEvents = (props: any) => (
  <Container
    sx={{
      marginBottom: 10,
      marginTop: 5,
      paddingLeft: 2,
      paddingRight: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      maxWidth: "1392px",
    }}
  >
    <MKTypography variant="h2" alignItems="center" sx={{ fontSize: 32 }}>
      News & Events
    </MKTypography>
    {isEmpty(props.data) ? (
      <Container sx={{ justifyContent: "center" }}>
        <NoDataTypography />
      </Container>
    ) : (
      <MKBox mt={5}>
        <Grid container rowSpacing={{ xs: 3, md: 5 }} columnSpacing={3} mt={0}>
          {props.data.map((item: any, index: number) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
              <SimpleCards
                id={item.id}
                image={item.image}
                title={item.title}
                categories={item.categories}
                publishedDate={item.publishedDate}
                eventStartDate={item.startDate}
                eventEndDate={item.endDate}
                eventStartTime={item.startTimeText}
                eventEndTime={item.endTimeText}
                location={item.location}
                type={item.type}
                detailUrl={`/${item.type}/${item.id}`}
              />
            </Grid>
          ))}
        </Grid>
      </MKBox>
    )}
  </Container>
);

export default NewsAndEvents;
