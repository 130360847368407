// @ts-nocheck
import { isEmpty } from "lodash";
import { gql } from "common/helper";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import colors from "assets/theme/base/colors";
import MKTypography from "components/MKTypography";
import HorizontalCards from "components/HorizontalCards";
import { compose, withFormik, withHooks } from "enhancers";
import NoDataTypography from "components/NoDataTypography";
import { Pagination, Form, Field, TextField, Select, Grid } from "components/main";

interface StartupProps {
  page: number;
  paginate: any;
  startups: any;
  startupCategoryOptions: [any];
  changePage: () => void;
}

const Startup = (props: StartupProps) => (
  <MKBox display="flex" flexDirection="column" padding={{ xs: "32px 32px", md: "64px 60px" }}>
    <MKBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <MKTypography variant="h3" mb="8px" sx={{ fontSize: "32px" }}>
        สตาร์ทอัพ
      </MKTypography>
      <MKTypography variant="h5" fontWeight="light">
        CU Innovation Hub portfolio
      </MKTypography>
    </MKBox>
    <Form>
      <MKBox mt={5}>
        <Grid
          container
          item
          rowSpacing={{ xs: 3 }}
          columnSpacing={3}
          mt={0}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm={6} lg={5}>
            <Field component={TextField} name="name" label="ชื่อ start up" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>
            <Field
              component={Select}
              name="relatedField"
              label="หมวดหมู่"
              options={props.startupCategoryOptions}
              fullWidth
            />
          </Grid>
          <Grid item xs={5} sm={3} lg={2}>
            <MKButton
              sx={{
                width: "100%",
                height: "48px",
                fontSize: "16px",
                fontWeight: 500,
                background: colors["primaryPink"].main,
                color: colors["white"].main,
              }}
              type="submit"
            >
              กรอง
            </MKButton>
          </Grid>
        </Grid>
      </MKBox>
    </Form>
    <MKBox name="startup_content">
      {isEmpty(props.startups) ? (
        <NoDataTypography></NoDataTypography>
      ) : (
        <MKBox>
          <Grid container rowSpacing={5} columnSpacing={3} mt={0}>
            {props.startups.map((startup: any) => (
              <Grid item xs={12} md={6} lg={4}>
                <HorizontalCards
                  id={startup?.id}
                  title={startup?.title}
                  image={startup?.image}
                  tagColor={colors["primaryPink"].main}
                  categories={startup?.categories || []}
                  detailUrl={`/startups/${startup?.id}`}
                  boxShadow="0px 4px 6px -1px #0000001A"
                  padding="24px"
                ></HorizontalCards>
              </Grid>
            ))}
          </Grid>
          <MKBox display="flex" justifyContent="flex-end" flexDirection="column" mt="40px">
            <Pagination
              page={props.page}
              totalPage={props.paginate?.totalPage}
              onPageChange={props.changePage}
            />
          </MKBox>
        </MKBox>
      )}
    </MKBox>
  </MKBox>
);

export const API = {
  FETCH_STARTUPS: gql`
    query FETCH_STARTUPS($filters: JSON, $published: Boolean) {
      startups(filters: $filters, published: $published) {
        startups {
          id
          name
          published
          image
          relatedField
        }
        paginate
      }
    }
  `,
  SETTINGS: gql`
    query SETTINGS($filter: [String!]) {
      settings {
        options {
          startupCategoryOptions(filter: $filter) {
            label
            value
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { useQuery, useRef, useEffect, useCallback, useMemo, useState, useHandleSubmit } = hooks;
    const { values, setInitialValues } = props;
    const [page, setPage] = useState(1);

    const { loading, error, data, refetch } = useQuery(API.FETCH_STARTUPS, {
      variables: {
        filters: {
          pagination: {
            page: 1,
            perPage: 6,
          },
          columnFilters: [],
          sort: [],
          filterPanel: {},
        },
        published: true,
      },
      fetchPolicy: "network-only",
    });

    const {
      loading: settingLoading,
      data: settingData,
      error: settingError,
    } = useQuery(API.SETTINGS, {
      variables: { filter: [] },
      fetchPolicy: "network-only",
    });

    const startupCategoryOptions = useMemo(() => {
      if (settingLoading || settingError) {
        return [];
      }

      const options = settingData?.settings?.options?.startupCategoryOptions;
      options.unshift({ label: "ทั้งหมด", value: "All" });
      return options;
    }, [settingLoading, settingData, settingError]);

    const paginate = useMemo(() => {
      if (loading || error) {
        return {};
      }

      const dataPaginate = data?.startups?.paginate || {};

      return dataPaginate;
    }, [loading, error, data]);

    const startups = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.startups.startups.map((startup: any) => {
        const categories = startup.relatedField ? [startup.relatedField] : [];
        return {
          ...startup,
          title: startup.name,
          categories,
        };
      });
    }, [loading, data, error]);

    const ref = useRef();

    const getColumnFilters = useCallback((values: any) => {
      const columnFilters = [];
      if (values.name) {
        columnFilters.push({ id: "name", value: values.name });
      }
      if (values.relatedField && values.relatedField !== "All") {
        columnFilters.push({ id: "relatedField", value: values.relatedField });
      }
      return columnFilters;
    }, []);

    useHandleSubmit(
      async (values: any) => {
        setPage(1);
        const columnFilters = getColumnFilters(values);
        refetch({
          filters: {
            pagination: {
              page: 1,
              perPage: 6,
            },
            columnFilters,
            sort: [],
            filterPanel: {},
          },
          published: true,
        });
      },
      [refetch, setPage, getColumnFilters]
    );

    const changePage = useCallback(
      async (selectedPage: any) => {
        setPage(selectedPage);
        const columnFilters = getColumnFilters(values);
        await refetch({
          filters: {
            pagination: {
              page: selectedPage,
              perPage: 6,
            },
            columnFilters,
            sort: [],
            filterPanel: {},
          },
          published: true,
        });
        location.href = "#top";
      },
      [refetch, values, setPage, getColumnFilters]
    );

    useEffect(() => {
      setInitialValues({
        name: null,
        relatedField: "All",
      });
    }, []);

    return { ref, startups, paginate, startupCategoryOptions, page, changePage };
  })
);

export default enhancer(Startup);
