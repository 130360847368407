// @ts-nocheck
import { compose, withHooks, withFormik } from "enhancers";
import { gql } from "common/helper";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Fragment } from "react";
import colors from "assets/theme/base/colors";
import appStore from "stores/appStore";

interface ResearchDetailProps {
  research: any;
  onUrlClick: () => void;
  downloadFile: () => void;
}

const ResearchDetail = (props: ResearchDetailProps) => (
  <MKBox display="flex" flexDirection="column" padding={{ xs: "32px 32px", md: "64px 60px" }}>
    <MKBox display="flex" alignItems="center">
      <MKTypography display="inline" variant="button" fontWeight="regular" sx={{ fontWeight: 500 }}>
        [RESEARCH]{"  "}
        {props.research?.relatedFields?.length > 0 && (
          <MKTypography
            variant="button"
            fontWeight="regular"
            sx={{
              color: colors["primaryPink"].main,
              fontWeight: 500,
            }}
          >
            {props.research?.relatedFields.map((relatedField: any) => (
              <Fragment key={relatedField}>#{relatedField} </Fragment>
            ))}
          </MKTypography>
        )}
      </MKTypography>
    </MKBox>
    <MKBox display="flex" mt="24px">
      <MKTypography variant="h3" fontWeight="bold" sx={{ fontSize: "28px" }}>
        {props.research?.name}
      </MKTypography>
    </MKBox>
    {props.research?.shortDescription && (
      <MKBox display="flex" flexDirection="column" mt="24px">
        <MKTypography variant="subtitle2" fontWeight="bold" sx={{ fontSize: "18px" }}>
          รายละเอียดโดยย่อ
        </MKTypography>
        <MKBox display="flex" mt="8px">
          <MKTypography variant="subtitle2" sx={{ textAlign: "justify" }}>{props.research?.shortDescription}</MKTypography>
        </MKBox>
      </MKBox>
    )}
    {props.research?.utilization && (
      <MKBox display="flex" flexDirection="column" mt="24px">
        <MKTypography variant="subtitle2" fontWeight="bold" sx={{ fontSize: "18px" }}>
          การนำไปใช้ประโยชน์
        </MKTypography>
        <MKBox display="flex" mt="8px">
          <MKTypography variant="subtitle2">{props.research?.utilization}</MKTypography>
        </MKBox>
      </MKBox>
    )}
    {props.research?.highlight && (
      <MKBox display="flex" flexDirection="column" mt="24px">
        <MKTypography variant="subtitle2" fontWeight="bold" sx={{ fontSize: "18px" }}>
          จุดเด่น
        </MKTypography>
        <MKBox display="flex" mt="8px">
          <MKTypography variant="subtitle2">{props.research?.highlight}</MKTypography>
        </MKBox>
      </MKBox>
    )}
    {props.research?.referenceUrl && (
      <MKBox display="flex" mt="40px" flexDirection="column">
        <MKTypography variant="h4" fontWeight="bold" sx={{ fontSize: "18px" }}>
          ข้อมูลเพิ่มเติม
        </MKTypography>
        <MKTypography
          variant="subtitle2"
          onClick={props.onUrlClick}
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            color: colors["info"].main,
            marginTop: "8px",
          }}
        >
          {props.research?.referenceUrl}
        </MKTypography>
      </MKBox>
    )}
  </MKBox>
);

const API = {
  FETCH_RESEARCH_BY_ID: gql`
    query FETCH_RESEARCH_BY_ID($id: ID!) {
      research(id: $id) {
        id
        name
        relatedFields
        referenceUrl
        shortDescription
        utilization
        highlight
        image
        file
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { useCallback, useQuery, useMemo, useEffect, useParams } = hooks;
    const {} = props;
    const { id } = useParams();
    const { loading, data, error } = useQuery(API.FETCH_RESEARCH_BY_ID, {
      variables: {
        id,
      },
      fetchPolicy: "network-only",
    });

    const research = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data.research;
    }, [loading, data, error]);

    const onUrlClick = useCallback(() => {
      window.open(research?.referenceUrl, "_blank");
    }, [research]);

    const downloadFile = useCallback(async () => {
      const response = await fetch(research?.file.url);
      const blob = await response.blob();
      const file = document.createElement("a");
      file.href = URL.createObjectURL(blob);
      file.download = research?.file.filename;
      document.body.appendChild(file);
      file.click();
      document.body.removeChild(file);
    }, [research]);

    useEffect(() => {
      if (research) {
        appStore.setDetailCover({
          image: research.image,
          type: "Research",
          detailTitle: research.name,
        });
      }
    }, [research]);

    return { research, onUrlClick, downloadFile };
  })
);

export default enhancer(ResearchDetail);
