// @ts-nocheck
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Container, Box } from "../../components/main/index";
import HorizontalCards from "components/HorizontalCards";
import NoDataTypography from "components/NoDataTypography";
import { isEmpty } from "lodash";
import { compose, withHooks } from "enhancers";

const Mentor = (props: any) => (
  <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "24px",
      marginBottom: "24px",
      background: "#F0F2F5",
      overflowY: "hidden",
      paddingTop: "60px",
      paddingBottom: "60px",
      borderRadius: "16px",
    }}
  >
    <MKBox display="flex" flexDirection="column" alignItems="center">
      <MKTypography variant="h2" alignItems="center" sx={{ fontSize: 32 }}>
        Mentor
      </MKTypography>
    </MKBox>
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        overflowX: "scroll",
        flexWrap: "nowrap",
        width: "100%",
        maxWidth: 1392,
        padding: "40px 0px",
      }}
    >
      {isEmpty(props.data) ? (
        <Container sx={{ justifyContent: "center" }}>
          <NoDataTypography />
        </Container>
      ) : (
        props.data?.map((mentor: any, index: number) => (
          <Box key={index} minWidth="408px" sx={{ marginLeft: 1.5, marginRight: 1.5 }}>
            <HorizontalCards
              title={`${mentor?.people?.firstname} ${mentor?.people?.lastname}`}
              image={mentor?.people?.image}
              categories={mentor?.people?.expertiseFields}
            />
          </Box>
        ))
      )}
    </Container>
    <MKBox>
      <MKButton
        variant="outlined"
        size="large"
        sx={{
          backgroundColor: "#CC0060",
          "&:hover": { color: "#CC0060" },
        }}
        onClick={props.goToMentor}
      >
        READ MORE
      </MKButton>
    </MKBox>
  </Container>
);

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useCallback, useNavigate } = hooks;
    const navigate = useNavigate();
    const goToMentor = useCallback(() => {
      navigate("/mentors");
    }, [navigate]);

    return {
      data: props.data,
      goToMentor,
    };
  })
);

export default enhancer(Mentor);
