// @ts-nocheck
import { Fragment } from "react";
import { gql } from "common/helper";
import MKBox from "components/MKBox";
import appStore from "stores/appStore";
import colors from "assets/theme/base/colors";
import { Grid } from "../../components/main/index";
import MKTypography from "components/MKTypography";
import imageDefault from "assets/images/image-default.png";
import { compose, withHooks, withFormik } from "enhancers";

const StartupDetail = (props: any) => (
  <MKBox display="flex" flexDirection="column" padding={{ xs: "32px 32px", md: "64px 60px" }}>
    <MKBox display="flex" alignItems="center">
      <MKTypography display="inline" variant="button" fontWeight="regular" sx={{ fontWeight: 500 }}>
        [STARTUP]{"  "}
        {props.startup?.categories.length > 0 && (
          <MKTypography
            variant="button"
            fontWeight="regular"
            sx={{
              color: colors["primaryPink"].main,
              fontWeight: 500,
            }}
          >
            {props.startup?.categories.map((category: any) => (
              <Fragment key={category}>#{category} </Fragment>
            ))}
          </MKTypography>
        )}
      </MKTypography>
    </MKBox>
    <MKBox mt={5}>
      <MKBox
        component="img"
        src={props.startup?.imageUrl || imageDefault}
        alt={props.startup?.name}
        borderRadius="lg"
        mb={5}
        width="110px"
        height="110px"
        position="relative"
        display="block"
        style={{ objectFit: "cover", boxShadow: "0px 4px 6px -1px #0000001A" }}
      />
      <Grid container rowSpacing={5}>
        <Grid item xs={12}>
          <MKTypography variant="h3" fontWeight="bold" sx={{ fontSize: "28px" }}>
            {props.startup?.name}
          </MKTypography>
        </Grid>
        <Grid item xs={12}>
          {props.startup?.description && (
            <MKBox display="flex">
              <MKTypography variant="subtitle2" whiteSpace="pre-line" sx={{ textAlign: "justify" }} >
                {props.startup?.description}
              </MKTypography>
            </MKBox>
          )}
        </Grid>
        <Grid item xs={12}>
          {props.startup?.url && (
            <MKBox display="flex" mt="32px" flexDirection="column">
              <MKTypography variant="h4" fontWeight="bold">
                ข้อมูลเพิ่มเติม
              </MKTypography>
              <MKTypography
                variant="subtitle2"
                onClick={props.onUrlClick}
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: colors["info"].main,
                }}
              >
                {props.startup?.url}
              </MKTypography>
            </MKBox>
          )}
        </Grid>
      </Grid>
    </MKBox>
  </MKBox>
);

const API = {
  FETCH_STARTUP: gql`
    query FETCH_STARTUP($id: ID!) {
      startup(id: $id) {
        id
        name
        relatedField
        url
        description
        image
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { useCallback, useQuery, useMemo, useEffect, useParams } = hooks;
    const { id } = useParams();

    const { loading, data, error } = useQuery(API.FETCH_STARTUP, {
      variables: { id },
      fetchPolicy: "network-only",
    });

    const startup = useMemo(() => {
      if (loading || error) {
        return null;
      }

      return {
        ...data?.startup,
        imageUrl: data?.startup.image?.url,
        categories: data?.startup.relatedField || [],
      };
    }, [loading, data, error]);

    const onUrlClick = useCallback(() => {
      window.open(startup?.url, "_blank");
    }, [startup]);

    useEffect(() => {
      if (startup) {
        appStore.setDetailCover({
          image: startup.image,
          type: "startup",
          detailTitle: startup.name,
        });
      }
    }, [startup]);
    return { data, onUrlClick, startup };
  })
);

export default enhancer(StartupDetail);
