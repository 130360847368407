import React, { useMemo, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { Field as FormikField, FastField as FormikFastField } from "formik";
import { isNil } from "lodash";

const Field = ({ fast = true, ...rest }) => {
  const { options, src, unit, helperText, disabled, onChange } = rest;

  const key = useMemo(() => {
    return uuidv4();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options), src, unit, helperText, disabled, onChange]);

  const required = rest.required;
  const componentValidation = rest.component?.validate;
  const validationSchema = rest.component?.validationSchema;
  const validate = useCallback(
    (value: any) => {
      let error: any = null;
      if (required && (isNil(value) || value === "")) {
        error = "client.yup.error.required";
      } else if (componentValidation) {
        error = componentValidation(value);
      } else if (validationSchema) {
        try {
          validationSchema.validateSync(value);
        } catch (e: any) {
          error = e.message;
        }
      }
      return error;
    },
    [required, componentValidation, validationSchema]
  );

  if (fast) {
    return <FormikFastField key={key} validate={validate} {...rest} />;
  } else {
    return <FormikField validate={validate} {...rest} />;
  }
};

export default Field;
