// @ts-nocheck
import { Fragment } from "react";
import MKBox from "components/MKBox";
import appStore from "stores/appStore";
import { isEmpty, isEqual } from "lodash";
import colors from "assets/theme/base/colors";
import MKTypography from "components/MKTypography";
import { compose, withHooks, withFormik } from "enhancers";
import { formatThaiDate, formatTime, gql } from "common/helper";

interface EventDetailProps {
  event: any;
  eventDateTime: string;
  onUrlClick: () => void;
}

const EventDetail = (props: EventDetailProps) => (
  <MKBox display="flex" flexDirection="column" padding={{ xs: "32px 32px", md: "64px 60px" }}>
    <MKBox display="flex" alignItems="center">
      <MKTypography display="inline" variant="button" fontWeight="regular" sx={{ fontWeight: 500 }}>
        [EVENT]{"  "}
        {props.event?.categories.length > 0 && (
          <MKTypography
            variant="button"
            fontWeight="regular"
            sx={{
              color: colors["primaryPink"].main,
              fontWeight: 500,
            }}
          >
            {props.event?.categories.map((category: any) => (
              <Fragment key={category}>#{category} </Fragment>
            ))}
          </MKTypography>
        )}
      </MKTypography>
    </MKBox>
    <MKBox display="flex" mt="32px">
      <MKTypography variant="h3" fontWeight="bold" sx={{ fontSize: "28px" }}>
        {props.event?.title}
      </MKTypography>
    </MKBox>
    {props.event?.description && (
      <MKBox display="flex" mt="32px">
        <MKTypography variant="subtitle2" whiteSpace="pre-line" sx={{ textAlign: "justify" }}>
          {props.event?.description}
        </MKTypography>
      </MKBox>
    )}
    {!isEmpty(props.eventDateTime) && (
      <MKBox display="flex" mt="32px" flexDirection="column">
        <MKTypography variant="h4" fontWeight="bold">
          วันเวลา
        </MKTypography>
        <MKTypography variant="subtitle2">{props.eventDateTime}</MKTypography>
      </MKBox>
    )}
    {props.event?.location && (
      <MKBox display="flex" mt="32px" flexDirection="column">
        <MKTypography variant="h4" fontWeight="bold">
          สถานที่
        </MKTypography>
        <MKTypography variant="subtitle2">{props.event?.location}</MKTypography>
      </MKBox>
    )}
    {props.event?.referenceUrl && (
      <MKBox display="flex" mt="32px" flexDirection="column">
        <MKTypography variant="h4" fontWeight="bold">
          ข้อมูลเพิ่มเติม
        </MKTypography>
        <MKTypography
          variant="subtitle2"
          onClick={props.onUrlClick}
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            color: colors["info"].main,
          }}
        >
          {props.event?.referenceUrl}
        </MKTypography>
      </MKBox>
    )}
  </MKBox>
);

const API = {
  FETCH_EVENT: gql`
    query FETCH_EVENT($id: ID!) {
      event(id: $id) {
        id
        title
        categories
        startDate
        endDate
        startTimeText
        endTimeText
        location
        referenceUrl
        description
        published
        image
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { useCallback, useQuery, useMemo, useEffect, useParams } = hooks;
    const {} = props;
    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(API.FETCH_EVENT, {
      variables: { id },
      fetchPolicy: "network-only",
    });

    const event = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data.event;
    }, [loading, data, error]);

    const onUrlClick = useCallback(() => {
      window.open(event?.referenceUrl, "_blank");
    }, [event]);

    useEffect(() => {
      if (event) {
        appStore.setDetailCover({ image: event.image, type: "Event", detailTitle: event.title });
      }
    }, [event]);

    const eventDateTime = useMemo(() => {
      if (!event) {
        return "";
      }

      const { startDate, endDate, startTimeText, endTimeText } = event;

      if (
        isEmpty(startDate) &&
        isEmpty(endDate) &&
        isEmpty(startTimeText) &&
        isEmpty(endTimeText)
      ) {
        return "";
      }

      const eventStartDate = formatThaiDate(startDate, "short");
      const eventEndDate = formatThaiDate(endDate, "short");

      const dateText = isEqual(eventStartDate, eventEndDate)
        ? `${eventStartDate}`
        : `${eventStartDate} - ${eventEndDate}`;

      const eventStartTime = formatTime(startTimeText);
      const eventEndTime = formatTime(endTimeText);

      const timeText = isEqual(eventStartTime, eventEndTime)
        ? `| ${eventStartTime}`
        : `| ${eventStartTime} - ${eventEndTime}`;

      const eventDateTime = !isEmpty(eventStartTime) ? `${dateText} ${timeText}` : `${dateText}`;

      return eventDateTime;
    }, [event]);

    return { event, onUrlClick, eventDateTime };
  })
);

export default enhancer(EventDetail);
