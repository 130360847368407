// @ts-nocheck
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import HorizontalCards from "components/HorizontalCards";
import NoDataTypography from "components/NoDataTypography";
import colors from "assets/theme/base/colors";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { compose, withHooks } from "enhancers";
import MKButton from "components/MKButton";
import { Container, Box } from "components/main";
import { isEmpty } from "lodash";

const StartUp = (props: any) => (
  <Container
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "56px",
      padding: "60px 0px",
      background: "white",
      overflowY: "hidden",
      width: "100%",
    }}
  >
    <MKTypography variant="h2" alignItems="center" sx={{ fontSize: 32, color: "#CC0060" }}>
      Startup
    </MKTypography>
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        overflowX: "scroll",
        flexWrap: "nowrap",
        width: "100%",
        padding: "40px 0px",
      }}
    >
      {isEmpty(props.data) ? (
        <Container sx={{ justifyContent: "center" }}>
          <NoDataTypography />
        </Container>
      ) : (
        props.data?.map((data: any, index: number) => (
          <Box
            key={index}
            minWidth="408px"
            sx={{
              marginLeft: 1.5,
              marginRight: 1.5,
            }}
          >
            <HorizontalCards
              id={data?.id}
              title={data?.name}
              image={data?.image}
              tagColor={colors["primaryPink"].main}
              categories={data?.categories}
              detailUrl={`/startups/${data?.id}`}
              boxShadow="0px 4px 6px -1px #0000001A"
            />
          </Box>
        ))
      )}
    </Container>
    <MKBox>
      <MKButton
        variant="outlined"
        size="large"
        sx={{ backgroundColor: "#CC0060", "&:hover": { color: "#CC0060" } }}
        onClick={props.goToStartup}
      >
        READ MORE
      </MKButton>
    </MKBox>
  </Container>
);

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useCallback, useNavigate } = hooks;
    const navigate = useNavigate();
    const goToStartup = useCallback(() => {
      navigate("/startups");
    }, [navigate]);

    return {
      data: props.data,
      goToStartup,
    };
  })
);

export default enhancer(StartUp);
