// @ts-nocheck
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { compose, withHooks } from "enhancers";
import styled from "styled-components";
import { gql } from "common/helper";
import HorizontalCards from "components/HorizontalCards";
import NoDataTypography from "components/NoDataTypography";
import { isEmpty } from "lodash";
import { Grid } from "components/main";

const ContentBox = styled(MKBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px;
`;

const Title = styled(MKBox)`
  display: flex;
  text-align: center;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
`;

const GroupCard = styled(MKBox)`
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  width: 100%;
`;

const MentorIndexPage = (props: any) => (
  <>
    <ContentBox>
      <Title>
        <MKTypography variant="h2" sx={{ fontSize: 32 }}>
          ผู้เชี่ยวชาญของเรา
        </MKTypography>
        <MKTypography variant="body2" color="secondary" sx={{ fontSize: 20 }}>
          เรามีผู้เชี่ยวชาญหลากหลายสาขา พร้อมให้คำปรึกษาในทุกปัญหาของคุณ
        </MKTypography>
      </Title>
      <br />
      <GroupCard>
        <Grid container spacing={{ xs: 2, sm: 3, lg: 3 }}>
          {isEmpty(props.mentors) ? (
            <Grid item xs={12}>
              <NoDataTypography></NoDataTypography>
            </Grid>
          ) : (
            props.mentors.map((mentor: any) => (
              <Grid item xs={12} sm={6} lg={4}>
                <HorizontalCards
                  title={mentor.title}
                  image={mentor.image}
                  categories={mentor.expertiseFields}
                />
              </Grid>
            ))
          )}
        </Grid>
      </GroupCard>
    </ContentBox>
  </>
);

const API = {
  FETCH_MENTORS: gql`
    query FETCH_MENTORS($filters: JSON) {
      mentors(filters: $filters) {
        mentors {
          id
          mentorType
          published
          people {
            firstname
            lastname
            expertiseFields
            image
          }
        }
      }
    }
  `,
};
const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useQuery, useMemo, useState } = hooks;
    const [filters] = useState({
      sort: [],
      pagination: {
        page: 1,
        perPage: 10,
      },
      columnFilters: [{ id: "published", value: true }],
    });
    const { loading, error, data } = useQuery(API.FETCH_MENTORS, {
      variables: {
        filters: { ...filters },
      },
      fetchPolicy: "network-only",
    });

    const mentors = useMemo(() => {
      if (loading || error) {
        return [];
      }
      const mapData = data.mentors?.mentors.map((mentor: any) => {
        const { firstname, lastname, expertiseFields, image } = mentor.people;
        const fullName = `${firstname} ${lastname}`;
        return {
          title: fullName,
          image: image,
          expertiseFields: expertiseFields,
        };
      });
      return mapData;
    }, [loading, error, data]);

    return { mentors };
  })
);

export default enhancer(MentorIndexPage);
