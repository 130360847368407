/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Fragment } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Card, ButtonBase } from "../main/index";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import colors from "assets/theme/base/colors";
import { isEmpty, isEqual, upperCase } from "lodash";
import imageDefault from "assets/images/image-default.png";
import { compose, withHooks, withFormik } from "enhancers";
import { formatThaiDate, formatTime } from "common/helper";

const SimpleCards = (props) => (
  <Card
    sx={{
      borderRadius: "8px",
      boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)",
    }}
  >
    <ButtonBase style={{ display: "block", textAlign: "initial" }} onClick={props.onClick}>
      <MKBox position="relative" p={2} width="100%" height="100%">
        <MKBox
          component="img"
          src={props.image?.url || imageDefault}
          alt={props.title}
          borderRadius="lg"
          width="100%"
          height="248px"
          position="relative"
          display="block"
          style={{ objectFit: "cover" }}
        />
        <MKBox p="16px 8px 8px 8px">
          <MKBox display="flex" alignItems="center">
            <MKBox display="flex" mr="8px">
              <MKTypography display="inline" variant="button" sx={{ fontWeight: 500 }}>
                [{upperCase(props.type)}]
              </MKTypography>
            </MKBox>
            {props.categories.length > 0 && (
              <MKTypography
                display="block"
                variant="button"
                color={colors["theme"]}
                fontWeight="semiMedium"
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  color: colors["primaryPink"].main,
                  fontWeight: 500,
                }}
              >
                {props.categories.map((category) => (
                  <Fragment key={category}>#{category} </Fragment>
                ))}
              </MKTypography>
            )}
          </MKBox>
          <MKBox display="flex" mt="16px" minHeight="55px">
            <MKTypography
              display="inline-block"
              variant="h5"
              fontWeight="bold"
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                fontWeight: 500,
              }}
            >
              {props.title}
            </MKTypography>
          </MKBox>
          {props.publishedDate && (
            <MKBox display="flex" mt="16px">
              <MKTypography variant="button" fontWeight="regular" sx={{ fontWeight: 500 }}>
                {formatThaiDate(props.publishedDate, "long")}
              </MKTypography>
            </MKBox>
          )}
          {props.eventDateTime && (
            <MKBox display="flex" mt="16px">
              <MKTypography
                variant="button"
                fontWeight="regular"
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  fontWeight: 500,
                }}
              >
                {props.eventDateTime}
              </MKTypography>
            </MKBox>
          )}
          {(props.location || props.type === "event") && (
            <MKBox display="flex" mt="8px">
              <MKTypography
                variant="subtitle2"
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                }}
              >
                {props.location ? props.location : "-"}
              </MKTypography>
            </MKBox>
          )}
        </MKBox>
      </MKBox>
    </ButtonBase>
  </Card>
);

const enhancer = compose(
  withFormik({}),
  withHooks((props, hooks) => {
    const { useCallback, useNavigate, useMemo } = hooks;
    const {
      id,
      image,
      title,
      publishedDate,
      categories = [],
      type,
      detailUrl,
      location,
      eventStartDate,
      eventEndDate,
      eventStartTime,
      eventEndTime,
    } = props;
    const navigate = useNavigate();

    const onClick = useCallback(() => {
      navigate(detailUrl);
    }, [navigate, detailUrl]);

    const eventDateTime = useMemo(() => {
      const startDate = formatThaiDate(eventStartDate, "short");
      const endDate = formatThaiDate(eventEndDate, "short");

      const dateText = isEqual(startDate, endDate) ? `${startDate}` : `${startDate} - ${endDate}`;

      const startTime = formatTime(eventStartTime);
      const endTime = formatTime(eventEndTime);

      const timeText = isEqual(startTime, endTime)
        ? `| ${startTime}`
        : `| ${startTime} - ${endTime}`;

      const result = !isEmpty(eventStartTime) ? `${dateText} ${timeText}` : `${dateText}`;

      if (!eventStartDate && !eventStartTime) {
        return "";
      }

      return result;
    }, [eventStartDate, eventEndDate, eventStartTime, eventEndTime]);

    return { id, image, title, publishedDate, categories, type, onClick, location, eventDateTime };
  })
);

export default enhancer(SimpleCards);
