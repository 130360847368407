export { gql } from "@apollo/client";
export { styled } from "@mui/material";
// export * as Yup from "yup";

export const safe = (block, safeValue) => {
  try {
    return block();
  } catch (e) {
    return safeValue;
  }
};

export const isServer = typeof window === "undefined";
export const isClient = !isServer;

export const replaceNameSpace = (initPrefix, ns) => {
  return ns.replaceAll(/(^\.|\$t\(\.)(?=\w)/g, (match) => {
    if (match.length && match.length === 1) {
      return "client." + initPrefix + ".";
    } else {
      const withOutLastChar = match.substring(0, match.length - 1);
      return withOutLastChar + "client." + initPrefix + ".";
    }
  });
};

export const formatThaiDate = (date, format) => {
  return new Date(date).toLocaleDateString("th-TH", {
    year: format === "short" ? "2-digit" : "numeric",
    month: format,
    day: "numeric",
  });
};

export const formatTime = (date, format) => {
  return new Date(date).toLocaleTimeString(undefined, {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
};

const monthShortNamesThai = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];
const monthLongNamesThai = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
];

export const formatThaiDateExceptYear = (date, format) => {
  const notFormatDate = new Date(date);
  const month =
    format === "short"
      ? monthShortNamesThai[notFormatDate.getMonth()]
      : monthLongNamesThai[notFormatDate.getMonth()];
  const year =
    format === "short" ? `${notFormatDate.getFullYear()}`.substr(2) : notFormatDate.getFullYear();
  return `${notFormatDate.getDate()} ${month} ${year}`;
};
