// @ts-nocheck
import { gql } from "@apollo/client";
import { compose, withHooks } from "enhancers";
import Card from "./card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import styled from "styled-components";
import NoDataTypography from "components/NoDataTypography";
import { isEmpty } from "lodash";
import { ButtonBase, Grid } from "components/main";

const ContentBox = styled(MKBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 40px;
`;

const CustomBox = styled(MKBox)`
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 20px;
  width: 100%;
`;

const GroupCard = styled(MKBox)`
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  width: 100%;
`;

const ServiceIndexPage = (props: any) => (
  <>
    <ContentBox>
      <MKBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          rowGap: "40px",
          margin: "40px 0px 40px 0px",
        }}
      >
        <CustomBox>
          <MKTypography variant="h2" sx={{ fontSize: 32 }}>
            {"บริการของเรา"}
          </MKTypography>
          <MKTypography variant="body2" color="secondary" sx={{ fontSize: 20 }}>
            {
              "Tincidunt aliquam leo enim at velit tellus. Aliquet magnis ultrices aliquam sem turpis  maecenas vulputate."
            }
          </MKTypography>
        </CustomBox>
      </MKBox>
      <GroupCard>
        <Grid container spacing={3}>
          {isEmpty(props.services) ? (
            <Grid item xs={12}>
              <NoDataTypography></NoDataTypography>
            </Grid>
          ) : (
            props.services.map((service: any) => (
              <Grid item xs={12} md={6} lg={4}>
                <ButtonBase
                  sx={{ width: "100%" }}
                  onClick={() => {
                    window.open(service.referenceUrl, "_blank");
                  }}
                >
                  <Card service={service} />
                </ButtonBase>
              </Grid>
            ))
          )}
        </Grid>
      </GroupCard>
    </ContentBox>
  </>
);

const API = {
  FETCH_SERVICES: gql`
    query FETCH_SERVICES($filters: JSON) {
      services(filters: $filters) {
        services {
          id
          name
          description
          referenceUrl
          image
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useQuery, useState, useMemo, useEffect, useCallback } = hooks;
    const [filters] = useState({
      sort: [],
      pagination: {
        page: 1,
        perPage: 10,
      },
      columnFilters: [{ id: "published", value: true }],
    });
    const { loading, data, error, refetch } = useQuery(API.FETCH_SERVICES, {
      variables: {
        filters: { ...filters },
      },
    });
    const services = useMemo(() => {
      if (loading || error) return [];
      return data?.services?.services;
    }, [data, loading, error]);

    return { services };
  })
);

export default enhancer(ServiceIndexPage);
