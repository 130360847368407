/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import ReactDOM from "react-dom";

// const container = document.getElementById("root");
// const root = ReactDOMClient.createRoot(container);

import { ApiProvider } from "api";
import { Provider } from "react-redux";

import store from "stores";
import "./styles/custom-font.css";

// root.render(
//   <Provider store={store}>
//     <ApiProvider>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </ApiProvider>
//   </Provider>
// );

ReactDOM.render(
  <Provider store={store}>
    <ApiProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApiProvider>
  </Provider>,
  document.getElementById("root")
);
