// @ts-nocheck
import { compose, withHooks } from "enhancers";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import styled from "styled-components";
import DefaultImage from "assets/images/image-default.png";

const CustomBox = styled(MKBox)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  word-break: break-word;
`;

interface CardProps {
  image: any;
  name: string;
  description: string;
}

const ServiceCard = (props: CardProps) => {
  return (
    <>
      <CustomBox position="relative">
        <MKBox
          component="img"
          src={props.image?.url || DefaultImage}
          alt={props.name}
          width="48px"
          height="48px"
          borderRadius="8px"
          style={{ objectFit: "contain" }}
          mb="16px"
        />
        <MKTypography variant="h3" sx={{ fontSize: 20, fontWeight: 500 }} mb="12px">
          {props.name}
        </MKTypography>
        <MKTypography
          variant="subtitle1"
          color="secondary"
          sx={{ fontSize: 16, textAlign: "center" }}
        >
          {props.description}
        </MKTypography>
      </CustomBox>
    </>
  );
};

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { service } = props;
    return {
      name: service.name,
      description: service.description ? service.description : "-",
      referenceUrl: service.referenceUrl,
      image: service.image,
    };
  })
);

export default enhancer(ServiceCard);
