import { ApolloClient, InMemoryCache, ApolloProvider, from, gql } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { createUploadLink } from "apollo-upload-client";
import env from "../env";

let client;

const httpLink = createUploadLink({
  uri: env.API_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const token = "";
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

// {
//   graphQLErrors, networkError, operation, forward;
// }

const errorLink = onError(() => {
  // if (networkError) {
  //   switch (networkError?.statusCode) {
  //     case 401:
  //       // ignore 401 error for a refresh request
  //       if (operation.operationName === "GEN_REFRESH_TOKEN") {
  //         Modal.alert({
  //           className: "TokenExpire",
  //           title: t("client.api.modal401Title"),
  //           children: t("client.api.modal401Children"),
  //           okButtonLabel: t("client.api.modal401OkButtonLabel"),
  //           onOk: async ({ close }) => {
  //             appStore.logout();
  //           },
  //           disableBackdropClick: true,
  //         });
  //       } else {
  //         const observable = new Observable((observer) => {
  //           // used an anonymous function for using an async function
  //           (async () => {
  //             try {
  //               await refreshToken();
  //               // Retry the failed request
  //               const subscriber = {
  //                 next: observer.next.bind(observer),
  //                 error: observer.error.bind(observer),
  //                 complete: observer.complete.bind(observer),
  //               };
  //               forward(operation).subscribe(subscriber);
  //             } catch (err) {
  //               observer.error(err);
  //             }
  //           })();
  //         });
  //         return observable;
  //       }
  //       return;
  //     case 403:
  //       Modal.alert({
  //         title: t("client.api.modal403Title"),
  //         children: t("client.api.modal403Children"),
  //         okButtonLabel: t("client.api.modal403OkButtonLabel"),
  //         onOk: async ({ close }) => {
  //           appStore.logout();
  //           close();
  //         },
  //         disableBackdropClick: true,
  //       });
  //       return;
  //     case 500:
  //       Modal.alert({
  //         title: t("client.api.modal500Title"),
  //         children:
  //           networkError.result?.errors &&
  //           networkError.result?.errors[0]?.message
  //             ? t(networkError.result?.errors[0]?.message)
  //             : networkError.message,
  //         okButtonLabel: t("client.api.modal500OkButtonLabel"),
  //         onOk: async ({ close }) => {
  //           close();
  //         },
  //         disableBackdropClick: true,
  //       });
  //       return;
  //     default:
  //       return;
  //   }
  // }
  // if (networkError) console.log(`[Network error]: ${networkError}`);
});

client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
});

export const ApiProvider = (props) => (
  <ApolloProvider client={client}>{props.children}</ApolloProvider>
);

export const resetStore = client.resetStore;
export const clearStore = client.clearStore;

export { gql };

export default client;
