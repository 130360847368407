// @ts-nocheck
import { compose, withHooks, withFormik } from "enhancers";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import { gql } from "common/helper";
import SimpleCards from "components/SimpleCards";
import { Pagination, Grid } from "components/main";
import { isEmpty } from "lodash";
import NoDataTypography from "components/NoDataTypography";
interface NewsProps {
  paginate: any;
  news: any;
  changePage: () => void;
  page: number;
}

const News = (props: NewsProps) => (
  <MKBox display="flex" flexDirection="column" padding={{ xs: "32px 32px", md: "64px 60px" }}>
    <MKBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <MKTypography variant="h3" mb="8px" sx={{ fontSize: "32px" }} textAlign="center">
        ข่าวของเรา
      </MKTypography>
      <MKTypography variant="h5" fontWeight="light" textAlign="center">
        Odio adipiscing vitae a sollicitudin. Penatibus
      </MKTypography>
    </MKBox>
    {isEmpty(props.news) ? (
      <NoDataTypography></NoDataTypography>
    ) : (
      <MKBox mt={5}>
        <Grid container rowSpacing={{ xs: 3, md: 5 }} columnSpacing={3} mt={0}>
          {props.news.map((news: any) => (
            <Grid item xs={12} sm={6} lg={4}>
              <SimpleCards
                id={news.id}
                image={news.image}
                title={news.title}
                publishedDate={news.publishedDate}
                categories={news.categories}
                type="news"
                detailUrl={`/news/${news.id}`}
              />
            </Grid>
          ))}
        </Grid>
        <MKBox display="flex" justifyContent="flex-end" flexDirection="column" mt="40px">
          <Pagination
            page={props.page}
            totalPage={props.paginate?.totalPage}
            onPageChange={props.changePage}
          />
        </MKBox>
      </MKBox>
    )}
  </MKBox>
);

export const API = {
  FETCH_NEWS: gql`
    query FETCH_NEWS($filters: JSON, $published: Boolean) {
      allNews(filters: $filters, published: $published) {
        news {
          id
          title
          categories
          publishedDate
          image
        }
        paginate
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const {
      useUrlParams,
      useDelayEffect,
      useCallback,
      useHandleSubmit,
      useQuery,
      useRouter,
      useMemo,
      useEffect,
      useMutation,
      useState,
    } = hooks;
    const {} = props;
    const { loading, data, error, refetch } = useQuery(API.FETCH_NEWS, {
      variables: {
        filters: {
          sort: [],
          pagination: {
            page: 1,
            perPage: 6,
          },
          columnFilters: [],
        },
        published: true,
      },
      fetchPolicy: "network-only",
    });
    const [page, setPage] = useState(1);

    const news = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.allNews.news.map((news: any) => {
        return {
          ...news,
        };
      });
    }, [loading, data, error]);

    const paginate = useMemo(() => {
      if (loading || error) {
        return {};
      }
      return {
        ...data.allNews.paginate,
      };
    }, [loading, data, error]);

    const changePage = useCallback(
      async (selectedPage: any) => {
        setPage(selectedPage);
        await refetch({
          filters: {
            sort: [],
            pagination: {
              page: selectedPage,
              perPage: 6,
            },
            columnFilters: [],
          },
          published: true,
        });
        location.href = "#top";
      },
      [refetch, setPage]
    );

    return { paginate, news, changePage, page };
  })
);

export default enhancer(News);
