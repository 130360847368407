import React, { useState } from "react";
import MuiTextField from "@mui/material/TextField";
import { Autocomplete } from "formik-mui";
import { compose, defaultProps, withHooks } from "enhancers";
import { find, map, get, isNil, omit, isEmpty } from "lodash";
// import ListboxComponent from './ListboxComponent';
import Fuse from "fuse.js";
import { InputAdornment } from "@mui/material";
import {
  borders,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from "@mui/system";
import styled from "styled-components";
import { gql } from "common/helper";
// import T from '../T';
// import { replaceNameSpace } from '@/utils/helper';

const makeBoxProps = (Component) =>
  styled(Component)(
    borders,
    display,
    flexbox,
    palette,
    positions,
    shadows,
    sizing,
    spacing,
    typography
  );

const TextField = makeBoxProps(MuiTextField);

export const enhancer = compose(
  defaultProps({
    options: [],
    forceFix: true,
  }),
  withHooks((props, hooks) => {
    const {
      label,
      options: optionsList = [],
      fuse,
      transformDisplay,
      queryField = null,
      requiredLabel,
      ...restProps
    } = props;

    const { useMemo, useCallback, useLazyQuery, useEffect } = hooks;

    const API = useMemo(() => {
      return {
        SETTINGS: gql`
          query SETTINGS{
            settings{
              options{
                ${queryField}{
                  label
                  value
                }
              }
            }
          }
        `,
      };
    }, [queryField]);
    const [fetchSettings, { loading, error: queryError, data }] = useLazyQuery(API.SETTINGS);

    useEffect(() => {
      if (queryField) {
        fetchSettings();
      }
    }, [queryField]);

    const settings = useMemo(() => {
      if (loading || queryError || isEmpty(queryField)) {
        return optionsList || [];
      }
      return data?.settings?.options[queryField] || [];
    }, [loading, queryError, data, queryField]);

    const [options, setCustomOptions] = useState([]);

    useEffect(() => {
      if (settings) {
        setCustomOptions(settings);
      }
    }, [settings]);

    const customOptions = useMemo(() => {
      return map(options, "value");
    }, [options]);

    const freeSolo = props.freeSolo;
    const customGetOptionLabel = useCallback(
      (value) => {
        const item = find(options, { value });
        const label = item?.label || "";

        if (!label && freeSolo) {
          return value;
        } else {
          return label;
        }
      },
      [options, freeSolo]
    );

    // const FormHelperTextProps = useMemo(
    //   () => ({
    //     component: (props) => (
    //       <T className={props.className}>
    //         {replaceNameSpace('error', props.children)}
    //       </T>
    //     )
    //   }),
    //   []
    // );

    const touched = get(props.form.touched, props.field.name);
    const error = get(props.form.errors, props.field.name);
    const value = props.field.value;
    const required = props.required;
    const helperText = props.helperText;
    const placeholder = props.placeholder;
    const icon = find(options, { value })?.icon;
    const focused = value?.length > 0 ? true : false;
    const renderInput = useCallback(
      (params) => (
        <TextField
          {...params}
          error={touched && !!error}
          helperText={(touched && error) || helperText}
          placeholder={placeholder}
          label={label}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
            required: requiredLabel,
          }}
          focused={focused}
          value={value}
          required={required}
          InputProps={{
            ...params.InputProps,
            required: false,
            startAdornment: (
              <>
                {icon && <InputAdornment position="start">{icon}</InputAdornment>}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          // FormHelperTextProps={FormHelperTextProps}
        />
      ),
      [
        label,
        touched,
        error,
        value,
        icon,
        required,
        helperText,
        placeholder,
        requiredLabel,
        // FormHelperTextProps
      ]
    );

    const filterOptions = useCallback(
      (options, { inputValue }) => {
        if (inputValue || options.length > 200) {
          options = new Fuse(props.options, {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: ["value"],
            id: "label",
          }).search(inputValue ?? "", { limit: 5 });
          options = map(options, "item.value");
        }
        return options;
      },
      [props.options]
    );

    const autoSelect = props.freeSolo && isNil(props.autoSelect) ? true : props.autoSelect;

    const customRestProps = omit(restProps, ["forceFix"]);
    return {
      key: props.forceFix ? value : undefined,
      ...customRestProps,
      options: [...customOptions],
      getOptionLabel: customGetOptionLabel,
      renderInput,
      // ListboxComponent,
      filterOptions: fuse ? filterOptions : undefined,
      blurOnSelect: true,
      autoSelect,
      limitTags: 5,
    };
  })
);

export default enhancer(Autocomplete);
