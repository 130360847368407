import MKPagination from "components/MKPagination";
import Icon from "@mui/material/Icon";
import { compose, withHooks } from "enhancers";

import { dropRight, drop, last, first } from "lodash";

const Pagination = (props) => {
  return (
    <MKPagination>
      <MKPagination
        item
        onClick={() =>
          props.totalPage === 1 ? null : props.handleBackPress(props.currentPage - 1)
        }
      >
        <Icon>keyboard_arrow_left</Icon>
      </MKPagination>
      {props.currentPaginationList.map((page) => (
        <MKPagination
          key={page.value}
          item
          active={page.value === props.currentPage}
          sx={{
            width: 40,
            height: 40,
            marginLeft: 1,
            marginRight: 1,
          }}
          onClick={() => props.handleClick(page.value)}
        >
          {page.label}
        </MKPagination>
      ))}
      <MKPagination
        item
        onClick={() =>
          props.totalPage === props.currentPage
            ? null
            : props.handleForwardPress(props.currentPage + 1)
        }
      >
        <Icon>keyboard_arrow_right</Icon>
      </MKPagination>
    </MKPagination>
  );
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { page = 1, totalPage = 1, onPageChange } = props;

    const { useState, useCallback, useEffect } = hooks;

    const [currentPage, setCurrentPage] = useState(page);

    const [currentPaginationList, setCurrentPaginationList] = useState([]);

    useEffect(() => {
      const pageAmount = totalPage > 3 ? 3 : totalPage;
      const initialPaginationList = Array.from({ length: pageAmount }, (_, i) => {
        return {
          label: `${i + 1}`,
          value: i + 1,
        };
      });
      setCurrentPaginationList(initialPaginationList);
    }, [totalPage]);

    useEffect(() => {
      setCurrentPage(page);
    }, [page]);

    const handleForwardPress = useCallback(
      (selectedPage) => {
        if (selectedPage > totalPage) return;
        const lastIndexObj = last(currentPaginationList);

        if (selectedPage > lastIndexObj.value) {
          const newPaginationList = drop(currentPaginationList, 1).concat({
            label: `${selectedPage}`,
            value: selectedPage,
          });
          setCurrentPaginationList(newPaginationList);
        }

        setCurrentPage(selectedPage);
        onPageChange && onPageChange(selectedPage);
      },
      [totalPage, currentPaginationList, onPageChange]
    );

    const handleBackPress = useCallback(
      (selectedPage) => {
        if (selectedPage < 1) return;

        const firstIndexObj = first(currentPaginationList);

        if (selectedPage < firstIndexObj.value) {
          const newPaginationList = dropRight(currentPaginationList);

          newPaginationList.unshift({
            label: `${selectedPage}`,
            value: selectedPage,
          });

          setCurrentPaginationList(newPaginationList);
        }

        setCurrentPage(selectedPage);
        onPageChange && onPageChange(selectedPage);
      },
      [currentPaginationList, onPageChange]
    );

    const handleClick = useCallback(
      (selectedPage) => {
        if (selectedPage < 1 || selectedPage > totalPage) return;
        if (selectedPage > currentPage) {
          handleForwardPress(selectedPage);
        } else {
          handleBackPress(selectedPage);
        }
      },
      [totalPage, currentPaginationList, onPageChange]
    );

    return {
      currentPaginationList,
      handleBackPress,
      handleForwardPress,
      currentPage,
      handleClick,
      totalPage,
    };
  })
);

export default enhancer(Pagination);
