// @ts-nocheck
import { compose, withHooks, withFormik } from "enhancers";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import { gql } from "common/helper";
import SimpleCards from "components/SimpleCards";
import { Pagination, Form, Field, TextField, Select, Grid } from "components/main";
import { isEmpty } from "lodash";
import MKButton from "components/MKButton";
import NoDataTypography from "components/NoDataTypography";

interface ResearchProps {
  paginate: any;
  researches: any;
  changePage: () => void;
  researchRelatedFieldOptions: [any];
  page: number;
}

const Research = (props: ResearchProps) => (
  <MKBox display="flex" flexDirection="column" padding={{ xs: "32px 32px", md: "64px 60px" }}>
    <MKBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <MKTypography variant="h3" mb="8px" sx={{ fontSize: "32px" }} textAlign="center">
        หากสนใจงานวิจัยหรือต้องการข้อมูลเพิ่มเติมกรุณาติดต่อ
      </MKTypography>
      <MKTypography variant="h5" fontWeight="light" textAlign="center">
        ฝ่ายทรัพย์สินทางปัญญา ศูนย์กลางนวัตกรรมแห่งจุฬาลงกรณ์มหาวิทยาลัย 254 อาคารเฉลิมราชกุมารี 60
        พรรษา (อาคารจามจุรี 10) ชั้น 2 ถนนพญาไท แขวงวังใหม่ เขตปทุมวัน กรุงเทพฯ 10330 <br/>
        อีเมล cu.ihub@chula.ac.th โทร:02-218-4195-7
      </MKTypography>
    </MKBox>
    <Form>
      <MKBox mt={5}>
        <Grid
          container
          item
          rowSpacing={{ xs: 3 }}
          columnSpacing={3}
          mt={0}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm={6} lg={5}>
            <Field component={TextField} name="name" label="ชื่องานวิจัย" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>
            <Field
              component={Select}
              name="relatedFields"
              label="หมวดหมู่"
              options={props.researchRelatedFieldOptions}
              fullWidth
            />
          </Grid>
          <Grid item xs={5} sm={3} lg={2}>
            <MKButton
              variant="outlined"
              sx={{
                width: "100%",
                height: "48px",
                fontSize: "16px",
                fontWeight: 500,
                backgroundColor: "#CC0060",
                "&:hover": { color: "#CC0060" },
              }}
              type="submit"
            >
              กรอง
            </MKButton>
          </Grid>
        </Grid>
      </MKBox>
    </Form>
    {isEmpty(props.researches) ? (
      <NoDataTypography></NoDataTypography>
    ) : (
      <MKBox mt={5}>
        <Grid container rowSpacing={{ xs: 3, md: 5 }} columnSpacing={3} mt={0}>
          {props.researches.map((research: any) => (
            <Grid item xs={12} sm={6} lg={4}>
              <SimpleCards
                id={research.id}
                image={research.image}
                title={research.name}
                categories={research.relatedFields}
                type="research"
                detailUrl={`/research/${research.id}`}
              />
            </Grid>
          ))}
        </Grid>
        <MKBox display="flex" justifyContent="flex-end" flexDirection="column" mt="40px">
          <Pagination
            page={props.page}
            totalPage={props.paginate?.totalPage}
            onPageChange={props.changePage}
          />
        </MKBox>
      </MKBox>
    )}
  </MKBox>
);

export const API = {
  FETCH_RESEARCHES: gql`
    query FETCH_RESEARCHES($filters: JSON, $published: Boolean) {
      researches(filters: $filters, published: $published) {
        researches {
          id
          name
          relatedFields
          image
        }
        paginate
      }
    }
  `,
  SETTINGS: gql`
    query SETTINGS($filter: [String!]) {
      settings {
        options {
          researchRelatedFieldOptions(filter: $filter) {
            label
            value
          }
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { useCallback, useHandleSubmit, useQuery, useMemo, useEffect, useState } = hooks;
    const { values, setInitialValues } = props;
    const { loading, data, error, refetch } = useQuery(API.FETCH_RESEARCHES, {
      variables: {
        filters: {
          pagination: {
            page: 1,
            perPage: 6,
          },
          columnFilters: [],
          sort: [],
        },
        published: true,
      },
      fetchPolicy: "network-only",
    });
    const {
      loading: settingLoading,
      data: settingData,
      error: settingError,
    } = useQuery(API.SETTINGS, {
      variables: { filter: [] },
      fetchPolicy: "network-only",
    });
    const [page, setPage] = useState(1);

    const researchRelatedFieldOptions = useMemo(() => {
      if (settingLoading || settingError) {
        return [];
      }

      const options = settingData?.settings?.options?.researchRelatedFieldOptions;
      options.unshift({ label: "ทั้งหมด", value: "All" });
      return options;
    }, [settingLoading, settingData, settingError]);

    const getColumnFilters = useCallback((values: any) => {
      const columnFilters = [];
      if (values.name) {
        columnFilters.push({ id: "name", value: values.name });
      }
      if (values.relatedFields && values.relatedFields !== "All") {
        columnFilters.push({ id: "relatedFields", value: values.relatedFields });
      }
      return columnFilters;
    }, []);

    useHandleSubmit(
      async (values: any) => {
        setPage(1);
        const columnFilters = getColumnFilters(values);
        refetch({
          filters: {
            pagination: {
              page: 1,
              perPage: 6,
            },
            columnFilters,
            sort: [],
          },
          published: true,
        });
      },
      [refetch, setPage, getColumnFilters]
    );

    const researches = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.researches.researches;
    }, [loading, data, error]);

    const paginate = useMemo(() => {
      if (loading || error) {
        return {};
      }
      return data.researches.paginate;
    }, [loading, data, error]);

    const changePage = useCallback(
      async (selectedPage: any) => {
        setPage(selectedPage);
        const columnFilters = getColumnFilters(values);
        await refetch({
          filters: {
            pagination: {
              page: selectedPage,
              perPage: 6,
            },
            columnFilters,
            sort: [],
          },
          published: true,
        });
        location.href = "#top";
      },
      [refetch, values, setPage, getColumnFilters]
    );

    useEffect(() => {
      setInitialValues({
        name: null,
        relatedFields: "All",
      });
    }, []);

    return { paginate, researches, changePage, researchRelatedFieldOptions, page };
  })
);

export default enhancer(Research);
