// @ts-nocheck
import { gql } from "common/helper";
import { compose, withHooks } from "enhancers";
import Information from "./Information";
import StartUp from "./Startup";
import Mentor from "./Mentor";
import NewsAndEvents from "./NewsAndEvents";
import Banner from "./Banner";

import { concat, sortBy, take } from "lodash";
// import MKBox from "components/MKBox";
import { Container } from "components/main";

interface LandingProps {
  bannerData: {
    researchAmount: number;
    startupAmount: number;
    mentorAmount: number;
  };
  mentors: [any];
  newsAndEvents: [any];
  startups: [any];
}

const Landing = (props: LandingProps) => (
  <Container sx={{ display: "flex", flexDirection: "column", marginTop: 8, alignItems: "center" }}>
    <Banner bannerData={props.bannerData} />
    <Information />
    <StartUp data={props.startups} />
    <Mentor data={props.mentors} />
    <NewsAndEvents data={props.newsAndEvents} />
  </Container>
);

const API = {
  MENTORS: gql`
    query MENTORS($filters: JSON, $published: Boolean, $highlight: Boolean) {
      mentors(filters: $filters, published: $published, highlight: $highlight) {
        mentors {
          id
          mentorType
          published
          people {
            firstname
            lastname
            image
            expertiseFields
          }
        }
        paginate
      }
    }
  `,
  RESEARCHES: gql`
    query RESEARCHES($filters: JSON, $published: Boolean) {
      researches(filters: $filters, published: $published) {
        paginate
      }
    }
  `,
  STARTUP: gql`
    query STARTUP($filters: JSON, $published: Boolean, $highlight: Boolean) {
      startups(filters: $filters, published: $published, highlight: $highlight) {
        startups {
          id
          name
          relatedField
          image
        }
        paginate
      }
    }
  `,
  EVENTS: gql`
    query EVENTS($filters: JSON, $published: Boolean) {
      events(filters: $filters, published: $published) {
        events {
          id
          code
          title
          categories
          startDate
          endDate
          startTimeText
          endTimeText
          location
          image
          updatedAt
        }
        paginate
      }
    }
  `,
  NEWS: gql`
    query NEWS($filters: JSON, $published: Boolean) {
      allNews(filters: $filters, published: $published) {
        news {
          id
          code
          title
          categories
          image
          publishedDate
          updatedAt
        }
        paginate
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useQuery, useMemo } = hooks;

    const {
      loading: startupsLoading,
      data: startupsData,
      error: startupsError,
    } = useQuery(API.STARTUP, {
      variables: {
        sort: [],
        filters: {
          pagination: {
            page: 1,
            perPage: 100,
          },
        },
        published: true,
        highlight: true,
      },
      fetchPolicy: "network-only",
    });

    const {
      loading: startupsAmountLoading,
      data: startupsAmountData,
      error: startupsAmountError,
    } = useQuery(API.STARTUP, {
      variables: {
        sort: [],
        filters: {
          pagination: {
            page: 1,
            perPage: 100,
          },
        },
        published: true,
      },
      fetchPolicy: "network-only",
    });

    const {
      loading: mentorsLoading,
      data: mentorsData,
      error: mentorsError,
    } = useQuery(API.MENTORS, {
      variables: {
        sort: [],
        filters: {
          pagination: {
            page: 1,
            perPage: 100,
          },
        },
        published: true,
        highlight: true,
      },
      fetchPolicy: "network-only",
    });

    const {
      loading: mentorsAmountLoading,
      data: mentorsAmountData,
      error: mentorsAmountError,
    } = useQuery(API.MENTORS, {
      variables: {
        sort: [],
        filters: {
          pagination: {
            page: 1,
            perPage: 100,
          },
        },
        published: true,
      },
      fetchPolicy: "network-only",
    });

    const {
      loading: researchesLoading,
      data: researchesData,
      error: researchesError,
    } = useQuery(API.RESEARCHES, {
      variables: {
        filters: {
          sort: [],
          pagination: {
            page: 1,
            perPage: 3,
          },
        },
        published: true,
      },
      fetchPolicy: "network-only",
    });

    const {
      loading: newsLoading,
      data: newsData,
      error: newsError,
    } = useQuery(API.NEWS, {
      variables: {
        filters: {
          sort: [],
          pagination: {
            page: 1,
            perPage: 6,
          },
        },
        published: true,
      },
      fetchPolicy: "network-only",
    });

    const {
      loading: eventsLoading,
      data: eventsData,
      error: eventsError,
    } = useQuery(API.EVENTS, {
      variables: {
        filters: {
          pagination: {
            page: 1,
            perPage: 6,
          },
        },
        published: true,
      },
      fetchPolicy: "network-only",
    });

    const mentors = useMemo(() => {
      if (mentorsLoading || mentorsError) {
        return [];
      }

      return mentorsData?.mentors?.mentors;
    }, [mentorsLoading, mentorsData, mentorsError]);

    const startups = useMemo(() => {
      if (startupsLoading || startupsError) {
        return [];
      }

      return startupsData?.startups?.startups;
    }, [startupsLoading, startupsData, startupsError]);

    const news = useMemo(() => {
      if (newsLoading || newsError) {
        return [];
      }

      return newsData?.allNews?.news;
    }, [newsLoading, newsData, newsError]);

    const events = useMemo(() => {
      if (eventsLoading || eventsError) {
        return [];
      }

      return eventsData?.events?.events;
    }, [eventsLoading, eventsData, eventsError]);

    const newsAndEvents = useMemo(() => {
      const mergedArray = concat(news, events);
      const sortedMergedArray = take(sortBy(mergedArray, ["updatedAt"]).reverse(), 6);
      const customMergedArray = sortedMergedArray.map((data: any) => {
        const type = data.code.startsWith("NWS") ? "news" : "event";
        return {
          ...data,
          type,
          publishedDate: data?.publishedDate,
          startDate: data?.startDate,
          endDate: data?.endDate,
          startTimeText: data?.startTimeText,
          endTimeText: data?.endTimeText,
          location: data?.location || "-",
        };
      });

      return customMergedArray;
    }, [news, events]);

    const mentorAmount = useMemo(() => {
      if (mentorsAmountLoading || mentorsAmountError) {
        return 0;
      }

      const { total } = mentorsAmountData?.mentors?.paginate || 0;

      return total;
    }, [mentorsAmountLoading, mentorsAmountData, mentorsAmountError]);

    const researchAmount = useMemo(() => {
      if (researchesLoading || researchesError) {
        return 0;
      }

      const { total } = researchesData?.researches?.paginate;

      return total;
    }, [researchesLoading, researchesData, researchesError]);

    const startupAmount = useMemo(() => {
      if (startupsAmountLoading || startupsAmountError) {
        return 0;
      }

      const { total } = startupsAmountData?.startups?.paginate || 0;

      return total;
    }, [startupsAmountLoading, startupsAmountData, startupsAmountError]);

    const bannerData = useMemo(() => {
      return {
        researchAmount: researchAmount ?? 0,
        startupAmount: startupAmount ?? 0,
        mentorAmount: mentorAmount ?? 0,
      };
    }, [mentorAmount, researchAmount, startupAmount]);

    return {
      bannerData,
      startups,
      mentors,
      newsAndEvents,
    };
  })
);

export default enhancer(Landing);
