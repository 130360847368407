import { connect } from "react-redux";
import deepmerge from "deepmerge";

import appStore from "stores/appStore";
// import insuranceStore from "../stores/insuranceStore.ts";
// import enrollmentStore from "stores/enrollmentStore.ts";
// import attendeeStore from "stores/attendeeStore";

const storeMapping = {
  appStore,
  // insuranceStore,
  // enrollmentStore,
  // attendeeStore,
};

const withStores = (mapStoresToProps) =>
  connect((state, props) => {
    const stores = deepmerge(storeMapping, state.data, { clone: false });
    return mapStoresToProps(stores, props);
  });

export default withStores;
