import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { compose, withHooks } from "enhancers";

const NoDataTypography = (props) => (
  <>
    <MKBox display="flex" justifyContent="center" mt="40px">
      <MKBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="120px"
        height="100px"
      >
        <MKTypography variant="h5" fontWeight="light">
          ไม่มีข้อมูล
        </MKTypography>
      </MKBox>
    </MKBox>
  </>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    return {};
  })
);

export default enhancer(NoDataTypography);
