// @ts-nocheck
import { compose, withHooks } from "enhancers";
import { Grid, Container } from "components/main/index";
import MKTypography from "components/MKTypography";

const Banner = (props: any) => (
  <Container
    disableGutters
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      flexWrap: "wrap",
    }}
  >
    <Grid
      container
      rowSpacing={5}
      xs={12}
      justifyContent="center"
      sx={{
        width: "100vw",
        maxWidth: "1392px",
        backgroundColor: "black",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: 352,
          minWidth: 200,
        }}
      >
        <MKTypography variant="h1" alignItems="center" sx={{ color: "#CC0060" }}>
          {props.researchAmount ?? 0}
        </MKTypography>
        <MKTypography variant="body2" color="dark" sx={{ fontWeight: 500 }}>
          RESEARCH
        </MKTypography>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: 352,
          minWidth: 200,
        }}
      >
        <MKTypography variant="h1" alignItems="center" sx={{ color: "#CC0060" }}>
          {props.startupAmount ?? 0}
        </MKTypography>
        <MKTypography variant="body2" color="dark" sx={{ fontWeight: 500 }}>
          START UP
        </MKTypography>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: 352,
          minWidth: 200,
        }}
      >
        <MKTypography variant="h1" alignItems="center" sx={{ color: "#CC0060" }}>
          {props.mentorAmount ?? 0}
        </MKTypography>
        <MKTypography variant="body2" color="dark" sx={{ fontWeight: 500 }}>
          MENTOR
        </MKTypography>
      </Grid>
    </Grid>
  </Container>
);

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { bannerData } = props || {};
    const { researchAmount = 0, startupAmount = 0, mentorAmount = 0 } = bannerData || {};

    return {
      researchAmount,
      startupAmount,
      mentorAmount,
    };
  })
);

export default enhancer(Banner);
