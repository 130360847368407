/* eslint-disable react/prop-types */
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";

import { Outlet } from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import routes from "routes";
import { compose, withHooks, withStores } from "enhancers";

import landingCover from "assets/images/landing-page/landing_cover.jpg";
import ecosystemCover from "assets/images/eco-system-page/ecosystemCover.png";
import newsCover from "assets/images/news-page/news_cover.jpg";
import serviceCover from "assets/images/service-page/service-cover.jpg";
import mentorCover from "assets/images/mentor/mentor-cover.jpg";
import imageCoverDefault from "assets/images/image-cover-default.png";
import StartUpCover from "assets/images/startup-page/startup_cover.png";

import { useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { isEmpty } from "lodash";
import colors from "assets/theme/base/colors";
import { Link } from "react-router-dom";

const pageLayoutDetailObj = {
  "/landing": {
    coverImage: landingCover,
    title: "Discover, Explore, Innovate: \nEmpowering the Future of Research",
    justifyContent: "flex-start",
    lg: 9,
    coverHeight: "600px",
  },
  "/startups": {
    coverImage: StartUpCover,
    title: "Startup",
    justifyContent: "center",
    lg: 12,
  },
  "/news": {
    coverImage: newsCover,
    title: "News",
    justifyContent: "center",
    lg: 12,
    coverHeight: "401px",
  },
  "/event": {
    coverImage: newsCover,
    title: "Events",
    justifyContent: "center",
    lg: 12,
    coverHeight: "401px",
  },
  "/services": {
    coverImage: serviceCover,
    title: "Services",
    justifyContent: "center",
    lg: 12,
    coverHeight: "401px",
  },
  "/ecosystem": {
    coverImage: ecosystemCover,
    title: "Ecosystem",
    justifyContent: "center",
    lg: 12,
  },
  "/mentors": {
    coverImage: mentorCover,
    title: "Mentors",
    justifyContent: "center",
    lg: 12,
    coverHeight: "401px",
  },
  "/research": {
    coverImage: newsCover,
    title: "Research",
    justifyContent: "center",
    lg: 12,
    coverHeight: "401px",
  },
};

const PageLayout = (props) => (
  <MKBox display="flex" flexDirection="column" alignItems="center">
    <DefaultNavbar brand="DAP" routes={routes} sticky />
    <MKBox
      minHeight={props.customStyle.coverHeight}
      width="100%"
      sx={{
        backgroundImage: `url(${props.coverImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: !isEmpty(props.details) ? "center" : "",
        position: "relative",
        height: "auto",
      }}
    >
      <MKBox
        position="absolute"
        width="100%"
        height="100%"
        opacity="50%"
        sx={{ backgroundColor: "#000000" }}
      ></MKBox>
      {!isEmpty(props.details) ? (
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={props.customStyle.lg}
            justifyContent={props.customStyle.justifyContent}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {props.title}
            </MKTypography>
          </Grid>
        </Container>
      ) : (
        <Container>
          <Grid container item xs={12} mt="117px" padding="18px">
            <MKBox display="flex" alignItems="center" flexDirection="row">
              <MKTypography
                variant="subtitle2"
                sx={{ color: colors["textLight"].main, marginRight: "16px" }}
                display="inline"
              >
                {props.type}
              </MKTypography>
              <MKTypography
                variant="subtitle2"
                sx={{ color: colors["textLight"].main, marginRight: "16px" }}
              >
                /
              </MKTypography>
              <MKTypography
                variant="subtitle2"
                color="white"
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                }}
              >
                {props.detailTitle}
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      )}
    </MKBox>
    <MKBox alignItems="center">
      <Card
        sx={{
          py: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          maxWidth: 1392,
          width: "100vw",
        }}
      >
        <Outlet />
      </Card>
    </MKBox>
    <MKBox
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="center"
      maxWidth="1392px"
      sx={{ paddingLeft: 2, paddingRight: 2, marginTop: 7 }}
    >
      {/* <MKBox display="flex" flexDirection="row" sx={{ flexWrap: "wrap" }}> */}
      <Grid container justifyContent="space-between">
        <Grid item>
          <MKBox
            display="flex"
            flexDirection="column"
            width="100%"
            maxWidth="384px"
            sx={{ padding: 1 }}
          >
            <MKTypography>About us</MKTypography>
            <MKTypography variant="body2" sx={{ color: "#7B809A" }}>
              CU Innovation Hub <br/>
              Chaloem Rajakumari 60 Building (Chamchuri 10 Building),19 Floor, Soi Chula 12,
              Chulalongkorn University, Phyathai Road, Pathumwan, Bangkok, Thailand 10330
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid item>
          <MKBox
            display="flex"
            flexDirection="column"
            width="100vw"
            maxWidth="266px"
            sx={{ padding: 1 }}
          >
            <MKTypography>Contact us</MKTypography>
            <MKTypography variant="body2" sx={{ color: "#7B809A" }}>
              cu.ihub@chula.ac.th
              cu.innovation.hub@gmail.com 02-218-3106
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid item>
          <MKBox
            display="flex"
            flexDirection="column"
            width="100vw"
            maxWidth="160px"
            sx={{ padding: 1 }}
          >
            <MKTypography>Data source</MKTypography>
            <Link to="startups">
              <MKTypography variant="body2" sx={{ color: "#7B809A" }}>
                Startup
              </MKTypography>
            </Link>
            <Link to="research">
              <MKTypography variant="body2" sx={{ color: "#7B809A" }}>
                Research
              </MKTypography>
            </Link>
            <Link to="mentors">
              <MKTypography variant="body2" sx={{ color: "#7B809A" }}>
                Mentor
              </MKTypography>
            </Link>
          </MKBox>
        </Grid>
        <Grid item>
          <MKBox
            display="flex"
            flexDirection="column"
            width="100vw"
            maxWidth="160px"
            sx={{ padding: 1 }}
          >
            <MKTypography>Activities</MKTypography>
            <Link to="event">
              <MKTypography variant="body2" sx={{ color: "#7B809A" }}>
                Event
              </MKTypography>
            </Link>
            <Link to="news">
              <MKTypography variant="body2" sx={{ color: "#7B809A" }}>
                News
              </MKTypography>
            </Link>
            <Link to="services">
              <MKTypography variant="body2" sx={{ color: "#7B809A" }}>
                Service
              </MKTypography>
            </Link>
            <Link to="ecosystem">
              <MKTypography variant="body2" sx={{ color: "#7B809A" }}>
                Ecosystem
              </MKTypography>
            </Link>
          </MKBox>
        </Grid>
      </Grid>
    </MKBox>
    <Divider sx={{ width: "100%" }} />
    <MKTypography variant="body2" sx={{ color: "#7B809A", textAlign: "center" }}>
      Copyright ©2023 DAP, All Rights Reserved
    </MKTypography>
  </MKBox>
);

const enhancer = compose(
  withStores((stores, props) => ({
    detailCover: stores.appStore.detailCover,
  })),
  withHooks((props, hooks) => {
    const { detailCover } = props || {};

    const location = useLocation();
    const pathname = location.pathname;

    const details = pageLayoutDetailObj[pathname] || {};

    const coverImage =
      (!isEmpty(details) ? details?.coverImage : detailCover?.image?.url) || imageCoverDefault;
    const title = details?.title || "";
    const justifyContent = details?.justifyContent || "";
    const lg = details?.lg || 0;
    const type = detailCover?.type || "";
    const detailTitle = detailCover?.detailTitle || "";
    const coverHeight =
      details?.coverHeight || (!isEmpty(details) ? "401px" : { xs: "401px", md: "600px" });

    const customStyle = {
      justifyContent,
      lg,
      coverHeight,
    };

    return {
      customStyle,
      title,
      coverImage,
      details,
      type,
      detailTitle,
    };
  })
);

export default enhancer(PageLayout);
