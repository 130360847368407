// @ts-nocheck
import { gql } from "common/helper";
import { compose, withHooks } from "enhancers";
import * as d3 from "d3";
import { isEmpty } from "lodash";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import imageDefault from "assets/images/image-default.png";

const Ecosystem = (props: any) => {
  return (
    <MKBox display="flex" flexDirection="column" padding="64px 60px">
      <MKBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <MKTypography variant="h3" mb="8px" sx={{ fontSize: "32px" }}>
          Ecosystem
        </MKTypography>
        <MKTypography variant="h5" fontWeight="light">
          Morbi imperdiet rutrum tempor urna vitae
        </MKTypography>
      </MKBox>
      <MKBox display="flex" justifyContent="center" flexDirection="column" mt={10}>
        <div ref={props.ref} style={{ display: "flex", justifyContent: "center" }} className="App">
          <div id="my_dataviz" style={{ width: "80%" }}></div>
        </div>
      </MKBox>
    </MKBox>
  );
};

const API = {
  GROUP_ECOSYSTEM: gql`
    query GROUP_ECOSYSTEM {
      groupEcosystemPartner {
        dataGroup
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useQuery, useRef, useEffect, useCallback, useMemo } = hooks;

    const ref = useRef();

    const { loading, data, error, refetch } = useQuery(API.GROUP_ECOSYSTEM, {
      fetchPolicy: "network-only",
    });

    const dataObject = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return { name: "flare", children: data?.groupEcosystemPartner.dataGroup.children };
    }, [data]);

    const createChart = useCallback(
      (data: any) => {
        const width = 928;
        const height = 928;
        const pack = (data: any) =>
          d3.pack().size([width, height]).padding(8)(
            d3
              .hierarchy(data)
              .sum((d) => d.value)
              .sort((a: any, b: any) => b.value - a.value)
          );
        const root = pack(data);
        let focus = root;
        let view: d3.ZoomView;

        const color = d3
          .scaleLinear()
          .domain([0, 5])
          .range(["#F2DFE6", "#E37494"])
          .interpolate(d3.interpolateHcl);
        const svg = d3
          .select("#my_dataviz")
          .append("svg")
          .attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
          .style("display", "block")
          .style("margin", "0 -14px")
          .style("cursor", "pointer")
          .on("click", (event) => zoom(event, root));

        const defs = svg.append("defs");

        for (let i = 0; i < data.children.length; i++) {
          let subGroup = data.children[i]["children"];
          for (var j in subGroup) {
            let obj = subGroup[j].children;
            for (var k in obj) {
              defs
                .append("pattern")
                .attr("id", obj[k].id)
                .attr("width", "100%")
                .attr("height", "100%")
                .attr("patternContentUnits", "objectBoundingBox")
                .append("image")
                .attr("width", 1)
                .attr("height", 1)
                .attr("preserveAspectRatio", "none")
                .attr("xmlns:xlink", obj[k].link)
                .attr("xlink:href", obj[k].img || imageDefault);
            }
          }
        }

        var node = svg
          .append("g")
          .selectAll("circle")
          .data(root.descendants().slice(1))
          .join("circle")
          .attr("fill", (d: any) => {
            if (d.children) {
              return color(d.depth);
            } else {
              return "url(#" + d.data.id + ")";
            }
          })
          .on("mouseover", function () {
            d3.select(this).attr("stroke", "#000");
          })
          .on("mouseout", function () {
            d3.select(this).attr("stroke", null);
          })
          .on("click", (event, d) => {
            if (d.children) {
              zoom(event, d), event.stopPropagation();
            } else {
              d.data.link && window.open(d.data.link, "_blank");
            }
          });

        const label = svg
          .append("g")
          .style("font", "20px Anakotmai")
          .attr("pointer-events", "none")
          .attr("text-anchor", "middle")
          .selectAll("text")
          .data(root.descendants())
          .join("text")
          .style("fill", "#344767")
          .style("fill-opacity", (d) => (d.parent === root ? 1 : 0))
          .style("display", (d) => (d.parent === root ? "inline" : "none"))
          .text((d: any) => d.data.name);

        zoomTo([root.x, root.y, root.r * 2]);
        function zoomTo(v: any) {
          const k = width / v[2];

          view = v;

          label.attr("transform", (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
          node.attr("transform", (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
          node.attr("r", (d) => d.r * k);
        }

        function zoom(event: any, d: any) {
          const focus0 = focus;

          focus = d;

          const transition = svg
            .transition()
            .duration(event.altKey ? 7500 : 750)
            .tween("zoom", (d) => {
              const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
              return (t) => zoomTo(i(t));
            });
        }
      },
      [data]
    );

    useEffect(() => {
      refetch();
    }, [refetch]);

    useEffect(() => {
      if (!isEmpty(dataObject)) {
        createChart(dataObject);
      }
    }, [ref, data]);

    return { ref };
  })
);

export default enhancer(Ecosystem);
