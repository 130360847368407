import { Routes as ReactRoutes, Route, Navigate } from "react-router-dom";
// import Landing from "../pages/Landing/index";
// import News from "../pages/News/index";
// import NewsDetail from "../pages/News/detail";
import PageLayout from "../components/main/PageLayout";
import { compose, withHooks } from "../enhancers/index";
import routes from "routes";
import NewsDetail from "../pages/News/detail";
import EventDetail from "../pages/Event/detail";
import StartupDetail from "../pages/Startup/detail";
import ResearchDetail from "../pages/Research/detail";

const getRoutes = (allRoutes: any) =>
  allRoutes.map((route: any) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return <Route path={route.route} element={route.component} key={route.key} />;
    }

    return null;
  });

// prettier-ignore
// const AuthPages = () => (
//   <ReactRoutes>
//     {/* {getRoutes(routes)} */}
//     {/* <Route path="/*">
//       <Route index path="landing" element={<Landing />} />
//       <Route path="news/*" element={<News />}>
//         <Route path=":id" element={<NewsDetail />} />
//       </Route>
//       <Route path="event" element={<News />}>
//         <Route path=":id" element={<NewsDetail />} />
//       </Route>
//       <Route path="*" element={<Navigate to="landing" replace />} />
//     </Route> */}

//     <Route path="/*" element={<PageLayout />}>
//       <Route path="landing" element={<Landing />} />
//       <Route path="news" element={<News />} />
//       <Route path="news/:id" element={<NewsDetail />} />

//       {/* This is default route must be place in the last one */}
//       <Route path="*" element={<Navigate to="landing" replace />} />
//     </Route>
//   </ReactRoutes>
// );

// prettier-ignore
const MainPages = () => (
  <ReactRoutes>
    <Route path="/*" element={<PageLayout />}>
      {/* <Route path="landing" element={<Landing />} />
      <Route path="news" element={<News />} />
      <Route path="news/:id" element={<NewsDetail />} /> */}
      {getRoutes(routes)}
      <Route path="news/:id" element={<NewsDetail />} />
      <Route path="event/:id" element={<EventDetail />} />
      <Route path="startups/:id" element={<StartupDetail />} />
      <Route path="research/:id" element={<ResearchDetail />} />

      {/* This is default route must be place in the last one */}
      <Route path="*" element={<Navigate to="landing" replace />} />
    </Route>
  </ReactRoutes>
);

// interface RoutesProps {
//   isAuthorized: boolean;
// }

// prettier-ignore
const Routes = () => {
  // return props.isAuthorized ? <MainPages /> : <AuthPages />;
  return <MainPages />;
};

const enhancer = compose(
  withHooks(() => {
    const isAuthorized = true;

    return {
      // initialized,
      isAuthorized,
      // hasFirstAdmin,
      // isAdmin,
    };
  })
);

export default enhancer(Routes);
