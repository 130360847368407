import { pick, mapValues } from "lodash";
// import {
//   CustomTextField,
//   CustomSelect,
//   CustomDatePicker,
//   CustomTypography,
//   CustomButton,
//   CustomSwitchInput,
//   CustomTimePicker,
// } from "./main/index";
import { default as CustomTextField } from "./TextField";
import { default as CustomSelect } from "./Select";
// import CustomTabs from "./Tabs";
// import styled from "styled-components/macro";
import * as MuiCore from "@mui/material";
// import * as MuiLab from "@mui/lab";
// import {
//   CheckboxWithLabel as FormikMuiCheckboxWithLabel,
//   Checkbox as FormikMuiCheckbox,
//   Switch as FormikMuiSwitch,
// } from "formik-mui";
// import CustomUploadCover from "./CoverUpload";
// import CustomImageUpload from "./ImageUpload";
// import CustomAvatarUpload from "./AvatarUpload";

const MuiComponents = mapValues(
  {
    // ...pick(MuiCore, [
    //       "Avatar",
    //       "Paper",
    //       "Menu",
    //       "Breadcrumbs",
    //       "Divider",
    //       "IconButton",
    //       "Radio",
    //       "FormControlLabel",
    //       "Chip",
    //       // 'Button',
    //       "Switch",
    //       "Tab",
    // ]),
    //     ...pick(MuiLab, ["Alert"]),
    //     // ...pick(FormikMuiCore, []),
    //     Button: CustomButton,
    //     // Avatar: CustomAvatar,
    //     // Link: CustomLink,
    //     CheckboxWithLabel: FormikMuiCheckboxWithLabel,
    //     Checkbox: FormikMuiCheckbox,
    //     // ErrorMessage: CustomErrorMessage,
    //     // Select: CustomSelect,
    //     Typography: CustomTypography,
    //     // RadioGroup: CustomRadioGroup,
    TextField: CustomTextField,
    //     SwitchInput: CustomSwitchInput,
    //     // MuiTextField: CustomMuiTextField,
    //     DatePicker: CustomDatePicker,
    //     // Stepper: CustomStepper
    Select: CustomSelect,
    //     Tabs: CustomTabs,
    //     CoverUpload: CustomUploadCover,
    //     ImageUpload: CustomImageUpload,
    //     AvatarUpload: CustomAvatarUpload,
    //     TimePicker: CustomTimePicker,
  }
  //   // makeBoxProps
);

export const {
  //   Avatar,
  //   Paper,
  TextField,
  //   Typography,
  //   Alert,
  //   Button,
  //   Link,
  //   Checkbox,
  //   Menu,
  //   Breadcrumbs,
  //   Divider,
  //   ErrorMessage,
  //   IconButton,
  //   RadioGroup,
  //   Radio,
  //   FormControlLabel,
  Select,
  //   Chip,
  //   SwitchInput,
  //   Switch,
  //   // MuiTextField,
  //   Stepper,
  //   DatePicker,
  //   CheckboxWithLabel,
  //   Tabs,
  //   ImageUpload,
  //   CoverUpload,
  //   AvatarUpload,
  //   TimePicker,
} = MuiComponents;

export {
  Grid,
  Container,
  Card,
  CircularProgress,
  ButtonBase,
  Step,
  StepLabel,
  Collapse,
  InputAdornment,
  CardContent,
} from "@mui/material";

export const Box = MuiCore.Box;

export { default as Pagination } from "./Pagination";
// export { default as ImageLightbox } from "./ImageLightbox";
// export { default as Modal } from "./Modal";
export { default as Form } from "./Form";
export { default as Field } from "./Field";
// export { default as Table } from "./Table";
// export { default as MaterialTable } from "./MaterialTable";
// export * from "./main/index";
