// @ts-nocheck
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Grid, Container } from "../../components/main/index";
import InformationImage from "assets/images/landing-page/landing_information.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { compose, withHooks } from "enhancers";

const Information = (props: any) => (
  <Container
    sx={{
      marginTop: 7,
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "1306px",
      px: 3,
    }}
  >
    <Grid
      container
      alignItems="center"
      justifyContent={props.matches ? "space-between" : "center"}
      rowSpacing={3}
      xs={12}
    >
      <Grid item sx={{ alignItems: "center" }}>
        <MKTypography
          variant="h2"
          sx={{
            display: "flex",
            justifyContent: props.matches ? "flex-start" : "center",
            fontSize: 32,
            alignItems: "center",
          }}
        >
          Information
        </MKTypography>
        <MKTypography
          maxWidth="660px"
          variant="body2"
          color="secondary"
          sx={{
            display: "flex",
            justifyContent: props.matches ? "flex-start" : "center",
            alignItems: "center",
            textAlign: props.matches ? "start" : "center",
          }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor inci didunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exe rcitation
          ullamco laboris nisi ut aliquip.
        </MKTypography>
        <MKBox
          display="flex"
          mt={5}
          sx={{
            display: "flex",
            justifyContent: props.matches ? "flex-start" : "center",
          }}
        >
          <MKButton
            variant="outlined"
            size="large"
            sx={{ backgroundColor: "#CC0060", "&:hover": { color: "#CC0060" } }}
            onClick={props.goToMoreInfo}
          >
            READ MORE
          </MKButton>
        </MKBox>
      </Grid>
      <Grid item>
        <MKBox
          component="img"
          src={InformationImage}
          alt="InformationImage"
          borderRadius="lg"
          shadow="md"
          width="100vw"
          height="auto"
          maxWidth="516px"
          position="relative"
          zIndex={1}
        />
      </Grid>
    </Grid>
  </Container>
);

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useCallback, useNavigate } = hooks;
    const matches = useMediaQuery("(min-width:1224px)", { noSsr: true });
    const navigate = useNavigate();
    const goToMoreInfo = useCallback(() => {
      navigate("/research");
    }, [navigate]);

    return { matches, goToMoreInfo };
  })
);

export default enhancer(Information);
