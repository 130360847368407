import { Fragment } from "react";
import { Card, ButtonBase, Grid } from "../../components/main/index";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import imageDefault from "assets/images/image-default.png";
import colors from "assets/theme/base/colors";
import { compose, withHooks } from "enhancers";
import { isEmpty } from "lodash";
import styled from "styled-components";

const CustomTypography = styled(MKTypography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: 767px) {
    text-align: center;
  }
  @media (min-width: 768px) {
    text-align: start;
  }
`;

const ImageBox = styled(MKBox)`
  width: 110px;
  height: 110px;
  border-radius: 12px;
  object-fit: contain;
`;

const CardDetail = (props) => (
  <>
    <Grid container rowSpacing={{ lg: 3 }} columnSpacing={{ xs: 2, md: 0, lg: 2 }} mt={0}>
      <Grid item xs={12} md={6} lg={6}>
        <ImageBox
          component="img"
          src={props.image?.url || imageDefault}
          sx={{
            boxShadow: props.boxShadow,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <CustomTypography>{props.title}</CustomTypography>
          {props.categories?.length > 0 && (
            <CustomTypography
              variant="body2"
              fontWeight="light"
              color={colors["theme"]}
              fontSize="16px"
              sx={{
                color: props.tagColor,
              }}
            >
              {props.categories.map((category) => (
                <Fragment key={category}>{`#${category}\t`}</Fragment>
              ))}
            </CustomTypography>
          )}
        </MKBox>
      </Grid>
    </Grid>
  </>
);

const HorizontalCards = (props) => (
  <Card
    sx={{
      display: "flex",
      flexDirection: "row",
      padding: props.padding,
      alignItems: "center",
    }}
  >
    <ButtonBase
      sx={{ width: "100%" }}
      disabled={isEmpty(props.detailUrl)}
      onClick={props.toDetailPage}
    >
      <CardDetail
        image={props.image}
        title={props.title}
        categories={props.categories}
        tagColor={props.tagColor}
        boxShadow={props.boxShadow}
      />
    </ButtonBase>
  </Card>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useCallback, useNavigate } = hooks;
    const {
      id,
      categories,
      image,
      title,
      tagColor = colors["secondary"].main,
      boxShadow = "",
      padding = "24px 24px 24px 24px",
      detailUrl,
    } = props;

    const navigate = useNavigate();
    const toDetailPage = useCallback(() => {
      navigate(detailUrl);
    }, [detailUrl]);

    return { id, title, categories, image, tagColor, boxShadow, padding, toDetailPage, detailUrl };
  })
);

export default enhancer(HorizontalCards);
