// @ts-nocheck
import { compose, withHooks, withFormik } from "enhancers";
import { formatThaiDate, gql } from "common/helper";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Fragment } from "react";
import colors from "assets/theme/base/colors";
import appStore from "stores/appStore";

interface NewsDetailProps {
  news: any;
  onUrlClick: () => void;
}

const NewsDetail = (props: NewsDetailProps) => (
  <MKBox display="flex" flexDirection="column" padding={{ xs: "32px 32px", md: "64px 60px" }}>
    <MKBox display="flex" alignItems="center">
      <MKTypography display="inline" variant="button" fontWeight="regular" sx={{ fontWeight: 500 }}>
        [NEWS]{"  "}
        {props.news?.categories.length > 0 && (
          <MKTypography
            variant="button"
            fontWeight="regular"
            sx={{
              color: colors["primaryPink"].main,
              fontWeight: 500,
            }}
          >
            {props.news?.categories.map((category: any) => (
              <Fragment key={category}>#{category} </Fragment>
            ))}
          </MKTypography>
        )}
      </MKTypography>
    </MKBox>
    <MKBox display="flex" mt="32px">
      <MKTypography variant="h3" fontWeight="bold" sx={{ fontSize: "28px" }}>
        {props.news?.title}
      </MKTypography>
    </MKBox>
    {props.news?.description && (
      <MKBox display="flex" mt="32px">
        <MKTypography variant="subtitle2" sx={{ textAlign: "justify" }}>{props.news?.description}</MKTypography>
      </MKBox>
    )}
    <MKBox display="flex" mt="32px" flexDirection="column">
      <MKTypography
        variant="h4"
        fontWeight="bold"
        sx={{
          fontSize: "18px",
        }}
      >
        วันที่เผยแพร่
      </MKTypography>
      <MKTypography
        variant="subtitle2"
        sx={{
          marginTop: "8px",
        }}
      >
        {formatThaiDate(props.news?.publishedDate, "long")}
      </MKTypography>
    </MKBox>
    {props.news?.referenceUrl && (
      <MKBox display="flex" mt="32px" flexDirection="column">
        <MKTypography
          variant="h4"
          fontWeight="bold"
          sx={{
            fontSize: "18px",
          }}
        >
          ข้อมูลเพิ่มเติม
        </MKTypography>
        <MKTypography
          variant="subtitle2"
          onClick={props.onUrlClick}
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
            color: colors["info"].main,
            marginTop: "8px",
          }}
        >
          {props.news?.referenceUrl}
        </MKTypography>
      </MKBox>
    )}
  </MKBox>
);

const API = {
  FETCH_NEWS_BY_ID: gql`
    query FETCH_NEWS_BY_ID($id: ID!) {
      news(id: $id) {
        id
        title
        categories
        publishedDate
        published
        referenceUrl
        description
        image
      }
    }
  `,
};

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { useCallback, useQuery, useMemo, useEffect, useParams } = hooks;
    const {} = props;
    const { id } = useParams();
    const { loading, data, error } = useQuery(API.FETCH_NEWS_BY_ID, {
      variables: {
        id,
      },
      fetchPolicy: "network-only",
    });

    const news = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data.news;
    }, [loading, data, error]);

    const onUrlClick = useCallback(() => {
      window.open(news?.referenceUrl, "_blank");
    }, [news]);

    useEffect(() => {
      if (news) {
        appStore.setDetailCover({ image: news.image, type: "News", detailTitle: news.title });
      }
    }, [news]);

    return { news, onUrlClick };
  })
);

export default enhancer(NewsDetail);
